.Examples-body {
    max-width: 1300px;
    margin: 0 auto;
  }
  
  .Examples-body  .header {
    font-size: 40px;
    font-weight: bold;
    color: rgb(255, 234, 226);
  }
  