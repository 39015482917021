.App {
  text-align: center;
  height: 100vh;
  overflow: auto;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 auto;
  font-size: calc(12px + 2vmin);
  color: white;
  padding-top:80px;
  min-height: 20vh;
  cursor: pointer;
}

.App-conversion {
  min-width: 300px;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  background-image: url('./images/sand.svg') !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(224, 131, 69);
}

button {
  border-radius: 2px !important;
}

.example-link {
  cursor: pointer;
}

.link {
  color: white; 
  text-decoration: underline;
}

.header {
  margin: 12px auto;
  margin-top: 40px;
}

.service-list {
  color: white;
  width: 300px;
  margin: 0 auto;
  font-size: 22px;
  margin-left: 55px;
}

.service-list li {
  list-style-type: '👉';
  text-align: left;
  list-style-position: outside;
  padding-inline-start: 1.4ch;
  
}
.service-list li::marker {
  margin-right: 25px;
  
}
.snug-list {
  color: white;
  width: 100px;
  margin: 0 auto;
  font-size: 22px;
}

.snug-list li {
  list-style-type: '';
  text-align: left;
  padding-inline-start: 1.4ch;

}
.snug-list li::marker {
  list-style-type: '';
  text-align: left;
  

  color: #4b3ce6;  
  top: 25px;
  content: '<>';
}

.App-conversion-icon {
  height: 30px !important;
  padding-right: 20px;
}

.stack-row {
  max-width: 900px;
  margin: 0 auto !important;
}

.green-svg {
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
}

.blue-svg {
  filter: invert(22%) sepia(69%) saturate(5777%) hue-rotate(199deg) brightness(94%) contrast(102%);
}

.brown-svg {
  filter: invert(18%) sepia(62%) saturate(1994%) hue-rotate(20deg) brightness(96%) contrast(103%);
}

.purple-svg {
  filter: invert(29%) sepia(67%) saturate(6336%) hue-rotate(261deg) brightness(103%) contrast(101%);
}

.orange-svg {
  filter: invert(27%) sepia(79%) saturate(4912%) hue-rotate(5deg) brightness(104%) contrast(103%);
}

.delayShake {
  animation: shake 1.1s;
  animation-iteration-count: 2;
}


.App-conversion:hover {
  box-shadow: 1px 0px 15px 1px black;
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 50vh;
  z-index: 300;
}

.App-link {
  color: #61dafb;
}

.About-body {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}

.About-body .header {
  font-size: 40px;
  font-weight: bold;
  color: rgb(255, 234, 226);
}


.About-body .info {
  font-size: 25px;
  color: rgb(255, 234, 226);
  
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}


.About-body .info a {
  color: rgb(255, 234, 226);
}

.About-body .info .cert {
  width: 180px;
  margin: 0 auto;
  margin-top: 20px;
}

.selfie {
  height: 160px;
  width: 160px;
  border: 6px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0,0,0,.15), inset 0 0 0 1px #eceff1;
  background: #f7f8f9;
  margin: 0 auto;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*shake*/
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

/*shake*/
@keyframes shakeAlways {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-2px, 0px) rotate(1deg); }
  30% { transform: translate(2px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-2px, 1px) rotate(0deg); }
  70% { transform: translate(2px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}




/*firework*/

body {
  background: 
    linear-gradient(#0007, #0000),
    #123;
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

@keyframes firework {
  0% { transform: translate(var(--x), var(--initialY)); width: var(--initialSize); opacity: 1; }
  50% { width: 0.5vmin; opacity: 1; }
  100% { width: var(--finalSize); opacity: 0; }
}

/* @keyframes fireworkPseudo {
  0% { transform: translate(-50%, -50%); width: var(--initialSize); opacity: 1; }
  50% { width: 0.5vmin; opacity: 1; }
  100% { width: var(--finalSize); opacity: 0; }
}
 */
.firework,
.firework::before,
.firework::after
{
  --initialSize: 0.9vmin;
  --finalSize: 65vmin;
  --particleSize: 0.2vmin;
  --color1: yellow;
  --color2: khaki;
  --color3: white;
  --color4: lime;
  --color5: gold;
  --color6: mediumseagreen;
  --y: -70vmin;
  --x: -50%;
  --initialY: 90vmin;
  content: "";
  animation: firework 5.4s infinite;
  pointer-events: none !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, var(--y));
  width: var(--initialSize);
  aspect-ratio: 1;
  background: 
    /*
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 0%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 0%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 100% 100%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 0% 100%,
    */
    
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 50% 0%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 50%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 50% 100%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 0% 50%,
    
    /* bottom right */
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 80% 90%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 95% 90%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 90% 70%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 60%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 55% 80%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 70% 77%,
    
    /* bottom left */
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 22% 90%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 45% 90%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 70%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 10% 60%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 31% 80%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 28% 77%,
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 13% 72%,
    
    /* top left */
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 80% 10%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 95% 14%,
    radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 90% 23%,
    radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 100% 43%,
    radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 85% 27%,
    radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 77% 37%,
    radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 60% 7%,
    
    /* top right */
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 22% 14%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 45% 20%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 34%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 10% 29%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 31% 37%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 28% 7%,
    radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 13% 42%
    ;
  background-size: var(--initialSize) var(--initialSize);
  background-repeat: no-repeat;
}

.firework::before {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
/*   transform: translate(-20vmin, -2vmin) rotate(40deg) scale(1.3) rotateY(40deg); */
  transform: translate(-50%, -50%) rotate(40deg) scale(1.3) rotateY(40deg);
/*   animation: fireworkPseudo 2s infinite; */
}

.firework::after {
  --x: -50%;
  --y: -50%;
  --initialY: -50%;
/*   transform: translate(44vmin, -50%) rotate(170deg) scale(1.15) rotateY(-30deg); */
  transform: translate(-50%, -50%) rotate(170deg) scale(1.15) rotateY(-30deg);
/*   animation: fireworkPseudo 2s infinite; */
}

.firework:nth-child(2) {
  --x: 30vmin;
}

.firework:nth-child(2),
.firework:nth-child(2)::before,
.firework:nth-child(2)::after {
  --color1: pink;
  --color2: violet;
  --color3: fuchsia;
  --color4: orchid;
  --color5: plum;
  --color6: lavender;  
  --finalSize: 80vmin;
  left: 30%;
  top: 60%;
  animation-delay: -0.65s;
}

.firework:nth-child(3) {
  --x: -30vmin;
  --y: -50vmin;
}

.firework:nth-child(3),
.firework:nth-child(3)::before,
.firework:nth-child(3)::after {
  --color1: cyan;
  --color2: lightcyan;
  --color3: lightblue;
  --color4: PaleTurquoise;
  --color5: SkyBlue;
  --color6: lavender;
  --finalSize: 65vmin;
  left: 70%;
  top: 60%;
  animation-delay: -1.15s;
}



.firework:nth-child(4) {
  --x: -30vmin;
  --y: -50vmin;
}

.firework:nth-child(4),
.firework:nth-child(4)::before,
.firework:nth-child(4)::after {
  --color1: rgb(244, 122, 208);
  --color2: rgb(247, 213, 128);
  --color3: rgb(255, 126, 126);
  --color4: rgb(214, 171, 250);
  --color5: SkyBlue;
  --color6: lavender;
  --finalSize: 42vmin;
  left: 60%;
  top: 60%;
  animation-delay: -2.15s;
}












.hover-link {
  /* ... existing styles ... */

  transition: transform 0.3s ease-in-out;
  background-color: transparent;
  margin: 10px !important;
  padding: 0 !important;
}

.hover-link:hover {
  transform: translateY(-5px);
  animation-name: hover-animation;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  border-radius: 25px;
}

@keyframes hover-animation {
  0% {
      transform: translateY(0);
      box-shadow: 0px 10px 1000px 50px #4a4aee06;
  }
  50% {
      transform: translateY(-8px);
      box-shadow: 0px 10px 1000px 50px #4a4aee23;

  }
  100% {
      transform: translateY(-5px);
      box-shadow: 0px 10px 1000px 50px #4a4aee42;

  }
}


@media (max-width: 900px) {

  .App-conversion {
    font-size: 30px;
  }

  .ant-card {
    max-width: 80%;
    margin: 0 auto;
  }

  .ant-row {
    max-width: 100%;
    margin: 0 auto;
  }

  .ant-col {
    margin: 0 auto;
  }
}