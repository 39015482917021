

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
    max-width: 1200px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    justify-self: center;
}

.meme {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

img.meme  {
    max-height: 500px;
    object-fit: cover;
    width: 400px;
}

.meme:hover {
    transform: scale(1.05);
}

.caption {
    padding: 10px;
    text-align: center;
    font-size: 14px;
    color: #555;
}

@media  only screen and (max-width: 600px) {
    header {
        font-size: 18px;
    }

    .caption {
        font-size: 12px;
    }

    img.meme  {
        width: 80%;
    }
}